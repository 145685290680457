<template>
  <div class="home">
    <div class="contain">
      <div
        class="box-card"
        v-for="i in navigator"
        :key="i"
        @click="into(i.path)"
      >
        <div class="box-title">
          <p>{{ i.name }}</p>
          <div class="title-line"></div>
        </div>
        <img :src="i.img" alt="" />
      </div>
    </div>
    <!-- 弹框 -->
    <el-dialog
      v-model="dialogInfo.show"
      width="500px"
      :show-close="false"
      v-if="dialogInfo.show"
    >
      <div class="dialog-box" v-if="dialogInfo.status == 0">
      <div class="dialag-title1"> 提示：请核对姓名、身份证号码，有误请联系工作人员更正！</div>
        <div class="dialag-title">请补充您的个人信息</div>
        <div class="input-box">
          邮箱
          <el-input
            v-model="information.email"
            placeholder="请输入邮箱"
          ></el-input>
        </div>
        <div class="input-box">
          手机号码
          <el-input
            v-model="information.mobile"
            placeholder="请输入手机号码"
          ></el-input>
        </div>
        <div class="input-box">
          真实姓名
          <el-input
            v-model="information.name"
            placeholder="请输入真实姓名"
          ></el-input>
        </div>
        <div class="input-box">
          身份证
          <el-input
            v-model="information.idCard"
            placeholder="请输入身份证号"
          ></el-input>
        </div>
        <!-- <div class="input-box">
          <p>所在部门</p>
          <el-select
            v-model="dialogInfo.department"
            placeholder="请选择所在部门"
            style="width: 100%"
          >
            <el-option
              v-for="(i, _i) in department"
              :key="_i"
              :label="i.label"
              :value="i.value"
            ></el-option>
          </el-select>
        </div> -->
        <div class="next gradient-background" @click="dialogInfo.status = 1">
          下一步
        </div>
        <div style="text-align: center">
          <div class="pass" @click="dialogInfo.show = false">跳过</div>
        </div>
      </div>
      <div class="dialog-box" v-if="dialogInfo.status == 1">
      <div class="dialag-title1"> 提示：请核对姓名、身份证号码，有误请联系工作人员更正！</div>
        <div class="dialag-title">补充身份证信息</div>
        <div class="input-box">
          身份证人像面照片<span style="font-size: 12px;color: #d34f4f;">（图片大小不能超过3MB）</span>
          <el-upload
            @click="selectImg('idf')"
            class="avatar-uploader"
            action="https://api.gdcoh.com/tecs/api/upload"
            :show-file-list="false"
            :headers="headersInfo"
            :data="uploadData"
            :before-upload="beforeAvatarUpload"
            :on-change="(file) => handleAvatarSuccess(file, 'idCardFImage')"
            :on-error="handleError"
          >
            <img
              v-if="dialogInfo.idCardFImage"
              :src="dialogInfo.idCardFImage"
              class="avatar"
            />
            <img v-else src="../../assets/imgs/id_01.png" alt="" />
          </el-upload>
        </div>
        <div class="input-box">
          身份证国徽面照片<span style="font-size: 12px;color: #d34f4f;">（图片大小不能超过3MB）</span>
          <el-upload
            @click="selectImg('idb')"
            class="avatar-uploader"
            action="https://api.gdcoh.com/tecs/api/upload"
            :show-file-list="false"
            :headers="headersInfo"
            :data="uploadData"
            :before-upload="beforeAvatarUpload"
            :on-change="(file) => handleAvatarSuccess(file, 'idCardBImage')"
            :on-error="handleError"
          >
            <img
              v-if="dialogInfo.idCardBImage"
              :src="dialogInfo.idCardBImage"
              class="avatar"
            />
            <img v-else src="../../assets/imgs/id_02.png" alt="" />
          </el-upload>
        </div>
        <div class="next gradient-background" @click="submit">提交</div>
        <!-- <div class="gradient-border" @click="dialogInfo.status = 0">上一步</div> -->
        <div style="text-align: center; margin-top: 10px">
          <div class="pass" @click="dialogInfo.show = false">跳过</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "home",
  data() {
    return {
      information: {
        email: "",
        gender: "",
        idCard: "",
        idCardFImage: "",
        idCardBImage: "",
        mobile: "",
        name: "",
      },
      uploadData: {
        type: "idf",
      },
      department: [
        { label: "交付中心", value: 0 },
        { label: "研发中心", value: 1 },
        { label: "产品设计部", value: 2 },
      ],
      dialogInfo: {
        show: false,
        status: 0,
        idCardFImage: "",
        idCardBImage: "",
      },
      navigator: [
        {
          name: "我的课程",
          path: "/curriculum",
          img: require("../../assets/imgs/icon_02.png"),
        },
        {
          name: "我的考试",
          path: "/examination",
          img: require("../../assets/imgs/icon_01.png"),
        },
        {
          name: "我的证书",
          path: "/certificate",
          img: require("../../assets/imgs/icon_03.png"),
        },
      ],
    };
  },
  created() {
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.headersInfo = {
      Authorization: "Bearer " + userInfo.token,
    };
    // 获取用户个人信息
    this.$https
      .get("/api/my/home/getInfo")
      .then((res) => {
        if (res.success) {
          const data = res.data;
          this.information = Object.assign({}, data);
          if (!data.idCard || !data.idCardBImage || !data.idCardFImage) {
            this.dialogInfo = {
              show: true,
              status: 0,
              // idCardFImage: data.idCardFImage ? "https://tecsf.marsontec.com/" + data.idCardFImage : "",
              // idCardBImage: data.idCardBImage ? "https://tecsf.marsontec.com/" + data.idCardBImage : "",
              idCardFImage: data.idCardFImage
                ? "https://tecsf.gdcoh.com/" + data.idCardFImage
                : "",
              idCardBImage: data.idCardBImage
                ? "https://tecsf.gdcoh.com/" + data.idCardBImage
                : "",
            };
            this.dialogInfo.status = 0;
          }
        }
      })
      .catch((error) => {
        this.$message.error("系统异常：" + error);
      });
  },
  methods: {
    handleError(error) {
      this.$message.error("系统异常：" + error);
    },
    selectImg(type) {
      this.uploadData.type = type;
    },
    submit() {
      if (!this.information.idCardFImage) {
        this.$message.error("请上传身份证人像面照片");
        return;
      }
      if (!this.information.idCardBImage) {
        this.$message.error("请上传身份证国徽面照片");
        return;
      }
      this.$https
        .put("/api/my/home/updateInfo", this.information)
        .then((res) => {
          if (res.success) {
            this.$message.success("修改个人信息成功！");
            this.dialogInfo.show = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.debug);
        });
    },
    handleAvatarSuccess(file, type) {
      if (file.response) {
        this.information[type] = file.response.path;
        this.dialogInfo[type] = URL.createObjectURL(file.raw);
      }
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png";
      const isLt2M = true;

      if (!isJPG) {
        this.$message.error("图片只能是 jpg jpeg png 格式!");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 3MB!");
      }
      return isJPG && isLt2M;
    },
    into(path) {
      this.$router.push({
        path: path,
      });
    },
  },
};
</script>
<style lang="less" scope>
.home {
  position: relative;
  width: 100%;
  height: 100%;
  .contain {
    position: absolute;
    top: 50px;
    left: 0;
    bottom: 0;
    right: 0;
    height: 70%;
    width: 80%;
    margin: auto;
    text-align: center;
    .box-card {
      width: 26%;
      display: inline-block;
      margin: 0 1%;
      position: relative;
      cursor: pointer;
      .box-title {
        position: absolute;
        top: 10%;
        left: 10%;
        color: #fff;
        font-weight: bold;
        font-size: 20px;
      }
      .title-line {
        margin-top: 10px;
        height: 1px;
        background-color: #fff;
        border: 2px solid #fff;
        width: 70%;
        border-radius: 5px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.dialag-title {
  font-weight: bold;
  font-size: 16px;
  color: #677897;
  padding-top: 20px;
  text-align: center;
}
.dialag-title1 {
  font-weight: bold;
  font-size: 13px;
  color: #d34f4f;
  padding-top: 20px;
  text-align: center;
}

.input-box {
  margin-top: 10px;
  position: relative;
  line-height: 30px;
  color: #677897;
  input {
    background-color: #fff;
  }
  img {
    height: 200px;
    cursor: pointer;
  }
}

.next {
  margin: 20px 0;
}

.pass {
  display: inline-block;
  border-bottom: 1px solid #909296;
  cursor: pointer;
}
// 覆盖element样式
.el-dialog {
  border-radius: 5px !important;
}
.el-dialog__header {
  background-color: #f6f9fd;
  border-radius: 5px !important;
}
.el-dialog__body {
  padding: 0 20px 30px 20px !important;
  background-color: #f6f9fd;
  border-radius: 5px !important;
}
.el-dialog__title {
  color: #677897 !important;
}
</style>
